const WOW = require('wowjs');

window.wow = new WOW.WOW({
    live: false
});



/*  ===================================
|  |   |   |   Animation
======================================= */
function initAnimate() {

//animate on scroll
$(function(){

window.wow.init();
});

//Home animation on page load

$(window).on('load', function(){
$("#home-heading-1").addClass("animated fadeInDown");
$("#home-heading-2").addClass("animated fadeInLeft");
$("#home-text").addClass("animated zoomIn");
$("#home-btn").addClass("animated zoomIn");
$("#arrow-down i").addClass("animated fadeInDown infinite");

});

}
export { initAnimate };
