
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
require('owl.carousel');

function initTeamCarousel() {

document.addEventListener("turbolinks:load", function()
  {

    $("#team-members").owlCarousel(
      {
        items: 2,
        autoplay: true,
        smartSpeed: 700,
        loop: true,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        navText: ['<i class= "fa fa-angle-left fa-2x"></i>', '<i class= "fa fa-angle-right fa-2x"></i>'],
        responsive :{

          0:{
            items: 1
          },

          480: {
            items: 2}
           }


      });

  });

document.addEventListener("turbolinks:before-cache", function() {
  $('#team-members').owlCarousel('destroy');
});
}

export { initTeamCarousel };



