
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

function eraseModal() {
document.addEventListener("turbolinks:load", function()

{// makes sure that all site is loaded
                $(".modal").on("hidden.bs.modal", function(){

            $(this).removeData();
        });
});
}

export { eraseModal };
