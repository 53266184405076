/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("trix")
require("@rails/actiontext")
console.log('Hello World from Webpacker')
import 'bootstrap';


//import { initPreloader } from '../components/preloader';
//initPreloader();

import { initTeamCarousel } from '../components/team_carousel';
initTeamCarousel();

import { initMap } from '../components/map';
initMap();

//import { initMapAv } from '../components/mapav';
//initMapAv();

import { initNavigation } from '../components/navigation';
initNavigation();

import { initAnimate } from '../components/animate';
initAnimate();

require("trix")
require("@rails/actiontext")

import { loadDynamicBannerText } from '../components/type';

document.addEventListener('turbolinks:load', () => {
  // Call your JS functions here
  // [...]
  loadDynamicBannerText();
});

import { loadDynamicQuoteText } from '../components/quote';

document.addEventListener('turbolinks:load', () => {
  // Call your JS functions here
  // [...]
  loadDynamicQuoteText();
});

import { initTabs } from '../components/tabs';
initTabs();

import { eraseModal } from '../components/modal';
eraseModal();

//import { initChromeCSS } from '../components/css';
//initChromeCSS();


