import $ from 'jquery';

window.jQuery = $;
window.$ = $;

function initTabs() {
document.addEventListener("turbolinks:load", function(){// makes sure that all site is loaded
  $(".tab").on("click", function(e) {
    // Change active tab
    $(".tab").removeClass("active");
    $(this).toggleClass("active");
    // Hide all tab-content (use class="hidden")
    $(".tab-content").addClass("d-none");
    // Show target tab-content (use class="hidden")
    var target = $(this).data("target"); // "#bio" or "#projects" or "#contact"
    $(target).removeClass("d-none");
  });
});
}

export { initTabs };

