import Typed from 'typed.js';

const loadDynamicBannerText = () => {
  new Typed('#p-typed-text', {
    strings: [" Réserver"],
    typeSpeed: 120,
    loop: true
  });

}
export { loadDynamicBannerText };

