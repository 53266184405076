import Typed from 'typed.js';

const loadDynamicQuoteText = () => {
  new Typed('#quote-typed-text', {
    strings: ["  Le sportif va plus loin dans le plaisir que dans la douleur."],
    typeSpeed: 50,
    loop: true
  });
}


export { loadDynamicQuoteText };


